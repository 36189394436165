<template>
    <div class="advantage">
        <!-- 标题 -->
        <div class="atTitle">技术优势</div>
        <!-- 图文列表 -->
        <div class="atImgText">
            <ul>
                <li class="aitImg">
                    <img src="https://iv.vu818.com/img/service_5.png" alt="" class="aiImg" />
                </li>
                <li class="aitText">
                    <p class="atBig">MVC模块分离架构</p>
                    <p class="atSmall">
                        MVC使开发和维护用户接口的技术含量降低。 使用MVC模式使开发时间得到相当大的缩减，它使程序员）集中精力于业务逻辑，
                        界面程序员（HTML和JSP开发人员）集中精力于表现形式上。
                    </p>
                </li>
                <li class="aitText">
                    <p class="atBig">卓越的前端交互体验</p>
                    <p class="atSmall">
                        UI设计师和前端技术充分合作，在跨终端布局、色彩运用、 图标等汲取自然规律，将互动效果融合进场景组织中， 减少用户理解成本，
                        让人机交互行为发生的更加自然。
                    </p>
                </li>
                <li class="aitImg">
                    <img src="https://iv.vu818.com/img/service_6.png" alt="" class="aiImg" />
                </li>
                <li class="aitImg">
                    <img src="https://iv.vu818.com/img/service_7.png" alt="" class="aiImg" />
                </li>
                <li class="aitText">
                    <p class="atBig">信息安全二级等保</p>
                    <p class="atSmall">
                        我们在网站前后端系统及服务器端的环境配置严格遵循国家信息安全二级等保规范。 并和测评机构紧密合作，
                        提供政务机构及国资国企单位网站的二级等保修复服务。
                    </p>
                </li>
                <li class="aitText">
                    <p class="atBig">易维护的框架和代码</p>
                    <p class="atSmall">
                        采用高效代码管理工具，注重代码质量， 基于Vue、antdesgin、TP等成熟的开源框架，在编写过程中提供简洁的注释，
                        项目型产品在开发接口时，对接口性能进行流程化测试。
                    </p>
                </li>
                <li class="aitImg">
                    <img src="https://iv.vu818.com/img/service_8.png" alt="" class="aiImg" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Advantage',
};
</script>

<style lang="less" scoped>
.advantage {
    width: 100%;
    // height: 1750px;
    // margin-top: 50px;
    background-color: #fff;
    // 标题
    .atTitle {
        width: 30%;
        height: 100px;
        margin: 0 auto;
        line-height: 100px;
        text-align: center;
        font-size: 60px;
        font-weight: 900;
    }
    // 图文列表
    .atImgText {
        width: 100%;
        height: 1600px;
        // margin-top: 50px;
        ul {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            background-color: #eee;
            .aitImg {
                width: 50%;
                height: 400px;
                overflow: hidden;
                .aiImg {
                    width: 100%;
                    height: 100%;
                    transition: 0.5s;
                }
            }
            .aitText {
                width: 50%;
                height: 400px;
                .atBig {
                    width: 50%;
                    height: 100px;
                    line-height: 50px;
                    font-size: 45px;
                    font-weight: 900;
                    margin: 0 auto;
                    margin-top: 80px;
                }
                .atSmall {
                    width: 50%;
                    height: 100px;
                    line-height: 35px;
                    font-size: 18px;
                    margin: 0 auto;
                    margin-top: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
            .aitImg:hover {
                .aiImg {
                    transform: scale(1.1);
                }
            }
        }
    }
}
</style>
