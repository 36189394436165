<template>
    <div class="main">
        <div v-for="el in list" :key="el.id" :span="3">
            <div @mouseenter="hoverId = el.id" @mouseleave="hoverId = null">
                <button
                    :class="{ active: activeId == el.id }"
                    @click="
                        activeId = el.id;
                        getListData('', el.id);
                    "
                >
                    {{ el.category_title }}
                </button>
                <!-- 鼠标悬浮弹框 -->
                <div class="tan" v-show="hoverId == el.id && tanList">
                    <el-row style="height: 100%">
                        <el-col :span="5" class="bg"></el-col>
                        <el-col :span="19">
                            <el-row>
                                <!-- 一排八个 -->
                                <el-col v-for="e in tanList" :key="e.id" :span="3" class="erji">
                                    <span
                                        :class="{ active_span: activeId_er == e.id }"
                                        @click="
                                            activeId_er = e.id;
                                            hoverId = null;
                                            getListData(e.id, el.id);
                                        "
                                        >{{ e.category_title }}</span
                                    >
                                    <div v-show="activeId_er == e.id">
                                        <img src="https://iv.vu818.com/img/vu818.png" />
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            list: [],
            activeId: 0,
            activeId_er: 0,
            tanShow: false,
            hoverId: null,
            page: 1,
        };
    },
    async created() {
        this.list = await this.getData();
    },
    mounted() {
        let { id, fuid } = this.$route.query;
        if (fuid) {
            // 如果携带id则筛选
            this.getListData(id, fuid);
            // 滚动到分类的位置
            const element = document.querySelector(".clBox_top");
            element.scrollIntoView();
        } else {
            this.$nextTick(() => {
                this.getListData("", 0);
            });
        }
    },
    methods: {
        // 获取nav数据
        async getData() {
            let { data } = await axios.get("/api/project/category_client");
            data.unshift({
                id: 0,
                category_title: "全部",
            });
            return data;
        },
        // 点击携带id与fuid筛选客户案例
        async getListData(id, fuid) {
            // let {
            //     data: { data, count },
            // } = await axios.get("/api/project/getList", { params: { id: id || fuid || 0 } });

            this.$emit("getList", id, fuid);
            // 父id高亮
            this.activeId = fuid;
            // 子id高亮
            this.activeId_er = id ? id : null;
        },
    },
    computed: {
        tanList() {
            let el = this.list.find(e => e.id === this.hoverId);
            return el ? el.children : false;
        },
    },
    watch: {
        "$route.query"({ id, fuid }) {
            // console.log(id, fuid);
            if (id == this.activeId_er && fuid == this.activeId) return;
            // 如果携带id则筛选
            this.getListData(id, fuid);
            // 滚动到分类的位置
            const element = document.querySelector(".clBox_top");
            element.scrollIntoView();
        },
    },
};
</script>
<style lang="less" scoped>
.main {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    & > div {
        width: 12.5%;
        & > div {
            width: 150px;
            margin: 0 auto;
        }
        button {
            width: 150px;
            cursor: pointer;
            height: 45px;
            border-radius: 20px;
            border: none;
            font-size: 16px;
            margin-bottom: 10px;
            box-shadow: gainsboro 1px 1px;
            background-color: white;
            &:hover {
                background: #7982f1;
                color: #fff;
            }
        }
        .active {
            background: #7982f1;
            color: #fff;
        }
    }
    .tan {
        background: #fff;
        position: absolute;
        z-index: 999;
        left: 0;
        // top: 0;
        width: 99%;
        height: 400px;
        border-radius: 10px;
        margin: 0px 0.5%;
        box-shadow: 1px 1px 10px 1px gainsboro;
        .bg {
            background: url(https://iv.vu818.com/img/side-bg1.jpg) white 100% 100% no-repeat;
            height: 100%;
        }
        .erji {
            height: 45px;
            line-height: 45px;
            position: relative;
            font-size: 20px;
            & > div {
                width: 40px;
                height: 20px;
                overflow: hidden;
                position: absolute;
                left: 50%;
                top: 105%;
                transform: translate(-50%, -50%);
                img {
                    width: 1500px;
                    height: 2900px;
                    position: relative;
                    top: -129px;
                    right: 1378px;
                }
            }
            .active_span {
                font-size: 24px;
                color: #7982f1;
            }
            span {
                cursor: pointer;
                transition: 0.1s;
                &:hover {
                    font-size: 24px;
                    color: #7982f1;
                }
            }
        }
    }
}
</style>
