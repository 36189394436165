<template>
    <div class="main">
        <vue-waterfall :list="listData" :columnCount="6">
            <template slot-scope="{ e }">
                <div class="anli" @click="goDetail(e)">
                    <img v-lazy="e.little_image" class="little_image" />
                    <img :src="e.size_image" class="sizeImg" :style="`${e.size_position}:0px`" />
                    <div class="zz" v-if="e.qrcode || e.logo">
                        <img v-if="e.qrcode" :src="e.qrcode" />
                        <img v-else :src="e.logo" />
                    </div>
                </div>
            </template>
        </vue-waterfall>
        <!-- 暂无数据 -->
        <img v-if="!once" src="https://iv.vu818.com/img/vujzz.png" style="width: 20%" />
        <img v-if="listData.length == 0 && once" src="https://iv.vu818.com/img/zwsj.png" style="width: 20%" />
        <lazy-loading v-if="listData.length != 0 && once" :callBack="callBack"></lazy-loading>
    </div>
</template>

<script>
import VueWaterfall from "@/util/vue-waterfall.vue";
import LazyLoading from "@/util/LazyLoading.vue";
export default {
    components: { VueWaterfall, LazyLoading },
    props: ["ids"],
    data() {
        return {
            once: false,
            listData: [],
            page: 1,
            count: null,
        };
    },
    methods: {
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            this.$router.push({
                name: "listDetail",
                params: { id },
            });
        },
        // 获取数据
        async getData(id, fuid) {
            let {
                data: { data, count },
            } = await axios.get("/api/project/getList", { params: { id: id || fuid || 0, page: this.page } });
            this.page++;
            this.count = count;
            this.listData = this.listData.concat(data);
            this.$nextTick(() => {
                this.once = true;
            });
        },
        // 懒加载回调
        async callBack(start, remove) {
            let { id, fuid } = this.ids;
            await this.getData(id, fuid);
            start();
            if (this.listData.length >= this.count) remove();
        },
        show(a, b, c) {
            console.log(a, b, c);
        },
    },
    watch: {
        ids(newVal) {
            // 重置一些变量
            let { id, fuid } = newVal;
            this.once = false;
            this.listData = [];
            this.page = 1;
            this.getData(id, fuid);
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    .anli {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;
        transition: 0.5s;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px 1px;
        position: relative;
        cursor: pointer;
        .little_image {
            width: 100%;
            margin-bottom: -2%;
            object-fit: cover;
            // border-radius: 10px;
            position: relative;
        }
        .little_image[lazy="loading"] {
            animation: an 2s linear infinite;
            @keyframes an {
                0% {
                    opacity: 0;
                    // clip-path: polygon();
                    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
                    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
                }
                100% {
                    opacity: 1;
                    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                }
            }
        }
        .little_image[lazy="error"] {
            /*your style here*/
        }
        .little_image[lazy="loaded"] {
            /*your style here*/
            // transform: scale(2);
        }
        .sizeImg {
            position: absolute;
            bottom: 0px;
            max-width: 50%;
        }
        &:hover {
            transform: translateY(-5px);
            .little_image {
                // box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5) !important;
                position: relative;
                // box-shadow: 1px 1px 10px 1px gainsboro;

                // &::after {
                //     content: " ";
                //     display: block;
                //     width: 100%;
                //     height: 100%;
                //     position: absolute;
                //     background: rgba(0, 0, 0, 0.7);
                //     z-index: 100;
                // }
            }
            .zz {
                opacity: 1 !important;
            }
        }
        .zz {
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.7);
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: 0.5s;
            opacity: 0;
            top: 0;
            justify-content: center;
            color: white;
            font-size: 24px;
            & > img {
                max-height: 60%;
                max-width: 60%;
                // box-shadow: 1px 1px 10px 1px gainsboro;
            }
        }
    }
}
</style>
